import * as React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import SignIn from "./pages/login/SignIn";
// import SignInSide from "./pages/login/SignInSide";
import SignUp from "./pages/login/SignUp";
import Dashboard from "./pages/admin/areaLogada/Dashboard";
import Atendimentos from "./pages/admin/areaLogada/Atendimentos";
import Checkout from "./pages/site/Checkout";
import PosCheckout from "./pages/site/PosCheckout";
import Produtos from "./pages/site/Produtos";
import Produto from "./pages/site/Produto";
import FluxoProduto from "./pages/site/FluxoProduto";
import Resultado from "./pages/site/Resultado";
import { Analytics } from "@vercel/analytics/react";

function isAuthenticated() {
  const token = localStorage.getItem("token");
  return token && token.length > 0;
}

function App() {
  const isLoggedIn = isAuthenticated();

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/checkout/:type/:quantity" element={<Checkout />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/pos-checkout" element={<PosCheckout />} />
          <Route path="/produtos" element={<Produtos />} />
          <Route path="/produto/:id" element={<Produto />} />
          <Route path="/fluxo-produto/:id" element={<FluxoProduto />} />
          <Route path="/resultado" element={<Resultado />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/registrar" element={<SignUp />} />
          <Route path="/pos-checkout" element={<PosCheckout />} />
          <Route
            path="/admin/dashboard"
            element={isLoggedIn ? <Dashboard /> : <Navigate to="/" />}
          />
          <Route
            path="/admin/atendimentos"
            element={isLoggedIn ? <Atendimentos /> : <Navigate to="/" />}
          />
        </Routes>
      </Router>
      <Analytics />
    </>
  );
}

export default App;
