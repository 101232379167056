import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
// import Chip from "@mui/material/Chip";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

const tiers = [
  {
    title: "Psicologo",
    //title: "Bronze",
    price: "199,00",
    description: [
      "Duração 1 hora",
      "Atendimento online",
      "Acesso ao laudo",
      "Encaminhamento",
    ],
    buttonText: "Comprar",
    buttonVariant: "outlined",
    checkout: 1,
  },
  {
    title: "Nutricionista",
    //title: "Prata",
    // subheader: "Recomendado",
    price: "99,00",
    description: [
      "Duração 1 hora",
      "Atendimento online",
      "Acesso ao laudo",
      "Encaminhamento",
    ],
    buttonText: "Comprar",
    buttonVariant: "contained",
    checkout: 2,
  },
  {
    title: "Educação fisica",
    //title: "Ouro",
    price: "79,00",
    description: [
      "Duração 1 hora",
      "Atendimento online",
      "Acesso ao laudo",
      "Encaminhamento",
    ],
    buttonText: "Comprar",
    buttonVariant: "contained",
    checkout: 3,
  },
  {
    //title: "Ouro",
    title: "Médico",
    price: "109,00",
    description: [
      "Duração 30 minutos",
      "Atendimento online",
      "Acesso ao laudo",
      "Encaminhamento",
    ],
    buttonText: "Comprar",
    buttonVariant: "outlined",
    checkout: 4,
  },
];

export default function Pricing() {
  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
        backgroundColor: (theme) => theme.palette.background.default,
        borderRadius: "12px",
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", sm: "100%", md: "60%" }, // Mudança para garantir que ocupe 100% em xs
          textAlign: { xs: "center", sm: "left", md: "center" }, // Centralizar texto em xs
        }}
      >
        <Typography
          component="h2"
          variant="h5"
          color="text.primary"
          fontWeight="bold"
          sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }}
        >
          Nossos Produtos Incríveis
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ mt: 2, fontSize: { xs: "0.9rem", sm: "1rem" } }}
        >
          Descubra a qualidade e inovação que só os melhores produtos do mercado
          podem oferecer. <br />
          Transforme sua saúde e bem-estar com nossas soluções exclusivas!
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        alignItems="flex-start"
        justifyContent="center"
        flexWrap={"wrap"}
      >
        <Grid item xs={12} sm="auto">
          {" "}
          {/* Garanta que o botão ocupe toda a largura em xs */}
          <Button
            href="/produtos"
            variant="contained"
            color="primary"
            sx={{
              flexShrink: 0,
              bgcolor: "primary.main",
              "&:hover": {
                bgcolor: "primary.dark",
                transform: "scale(1.05)",
              },
              width: { xs: "100%", sm: "auto" }, // Botão ocupar 100% em xs
              padding: "12px 24px",
              fontSize: { xs: "0.9rem", sm: "1rem" }, // Ajuste do tamanho da fonte do botão
            }}
          >
            Explore Nossos Produtos
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
