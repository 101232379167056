import React from "react";
import { Button, Box, Typography, Container } from "@mui/material";
import { useNavigate } from "react-router-dom"; // Importa useNavigate

function PosCheckout() {
  const navigate = useNavigate(); // Hook para navegar

  const handleLogin = () => {
    navigate("/login"); // Navega para a página de login
  };

  const handleRegister = () => {
    navigate("/registrar"); // Navega para a página de registro
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ mt: 8 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Typography component="h1" variant="h5" sx={{ mb: 4 }}>
          Obrigado pela sua compra!
        </Typography>
        <Typography variant="body1" gutterBottom>
          Escolha uma opção para continuar:
        </Typography>
        <Box
          sx={{
            mt: 2,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleLogin}
          >
            Login
          </Button>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={handleRegister}
          >
            Registrar-se
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default PosCheckout;
