import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Box,
  Typography,
  Paper,
  Modal,
  CircularProgress,
} from "@mui/material";
import { useParams } from "react-router-dom";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import axios from "axios";

const FluxoProduto = () => {
  // Estados para armazenar os produtos, produto selecionado, valores do formulário e modal
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // Estados para armazenar e validar os dados do cliente
  const [emailComprador, setEmailComprador] = useState("");
  const [telefoneComprador, setTelefoneComprador] = useState("");
  const [showForm, setShowForm] = useState(true); // Controla se mostramos o formulário de contato ou o loading/result

  const { type } = useParams();

  // Carrega os produtos e inicializa o formulário
  useEffect(() => {
    fetch("../../products.json")
      .then((response) => response.json())
      .then((data) => {
        setProducts(data);
        const product = data.find((p) => p.id.toString() === type);
        setSelectedProduct(product);
        if (product && product.fluxoDinamico) {
          const initialValues = product.fluxoDinamico.reduce((acc, field) => {
            acc[field.id] = field.type === "checkbox" ? false : "";
            return acc;
          }, {});
          setFormValues(initialValues);
        }
      })
      .catch((error) => console.error("Erro ao carregar os produtos:", error));
  }, [type]);

  // Atualiza os valores do formulário conforme o usuário preenche
  const handleChange = (label, type, value) => {
    setFormValues((prev) => ({
      ...prev,
      [label]: type === "checkbox" ? value.target.checked : value.target.value,
    }));
  };

  // Função para validar se o e-mail é válido
  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  // Formata o telefone conforme o usuário digita
  const handlePhoneChange = (event) => {
    let { value } = event.target;
    value = value.replace(/\D/g, "");
    value = value.slice(0, 11);
    let formattedValue = "";
    if (value.length > 2) {
      formattedValue = `(${value.slice(0, 2)}) `;
      if (value.length > 6) {
        formattedValue += `${value.slice(2, 7)}-${value.slice(7, 11)}`;
      } else {
        formattedValue += value.slice(2);
      }
    } else {
      formattedValue = value;
    }
    setTelefoneComprador(formattedValue);
  };

  // Inicia a simulação de envio ao backend
  const handleSubmit = () => {
    setLoading(true);
    setShowForm(false); // Esconde o formulário de contato
    setModalMessage("Estamos preparando o seu resultado incrível...");

    // Simula um delay para imitar uma chamada à API
    setTimeout(() => {
      // Monta o payload com os IDs e respostas
      const payload = {
        produtoId: selectedProduct.id,
        email: emailComprador,
        telefone: telefoneComprador,
        formValues: Object.keys(formValues).map((id) => ({
          id: id,
          resposta: formValues[id],
        })),
      };

      // Faz a simulação de chamada à API usando Axios
      axios
        .post("https://api.exemplo.com/enviar-dados", payload)
        .then((response) => {
          setLoading(false);
          setModalMessage(
            "Seu resultado está pronto! 🎉 Aproveite sua experiência personalizada."
          );
        })
        .catch((error) => {
          setLoading(false);
          setModalMessage(
            "Seu resultado está pronto! 🎉 Aproveite sua experiência personalizada."
          );
        });
    }, 3000); // Simula um delay de 3 segundos
  };

  // Fecha o modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Abre o modal e exibe o formulário de contato
  const handleOpenModal = () => {
    setOpenModal(true);
    setShowForm(true);
  };

  if (!selectedProduct) {
    return <div>Carregando...</div>;
  }

  return (
    <Container
      maxWidth="md"
      sx={{
        padding: { xs: 2, sm: 4 },
        marginBottom: 4,
        backgroundColor: "#f5f5f7",
        borderRadius: 2,
        boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
      }}
    >
      <Button
        startIcon={<ArrowBackRoundedIcon />}
        component="a"
        href="/produtos"
        sx={{
          color: "#0071e3",
          textTransform: "none",
          fontSize: "16px",
          fontWeight: "bold",
          marginBottom: 2,
        }}
      >
        Voltar
      </Button>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} textAlign="center">
          <Typography
            variant="h4"
            component="h1"
            sx={{
              fontWeight: "bold",
              marginBottom: 2,
              fontSize: { xs: "1.8rem", sm: "2.4rem" },
              color: "#1d1d1f",
            }}
          >
            {selectedProduct.name}
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{
              marginBottom: 4,
              fontSize: { xs: "1rem", sm: "1.2rem" },
              color: "#6e6e73",
              maxWidth: "80%",
              margin: "0 auto",
            }}
          >
            {selectedProduct.description}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper
            elevation={3}
            sx={{
              padding: { xs: 2, sm: 4 },
              borderRadius: 3,
              backgroundColor: "#ffffff",
            }}
          >
            <form>
              {selectedProduct.fluxoDinamico.map((field, index) => (
                <Box key={index} sx={{ marginBottom: 3 }}>
                  {field.type === "string" && (
                    <TextField
                      fullWidth
                      label={field.label}
                      required={field.obrigatorio}
                      value={formValues[field.id] || ""}
                      onChange={(e) => handleChange(field.id, field.type, e)}
                      variant="outlined"
                      sx={{
                        marginBottom: 2,
                        ".MuiOutlinedInput-root": {
                          borderRadius: 2,
                          "&:hover": {
                            backgroundColor: "#f0f0f0",
                          },
                        },
                      }}
                    />
                  )}
                  {field.type === "inteiro" && (
                    <TextField
                      fullWidth
                      type="number"
                      label={field.label}
                      required={field.obrigatorio}
                      value={formValues[field.id] || ""}
                      onChange={(e) => handleChange(field.id, field.type, e)}
                      variant="outlined"
                      sx={{
                        marginBottom: 2,
                        ".MuiOutlinedInput-root": {
                          borderRadius: 2,
                          "&:hover": {
                            backgroundColor: "#f0f0f0",
                          },
                        },
                      }}
                    />
                  )}
                  {field.type === "checkbox" && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formValues[field.id] || false}
                          onChange={(e) =>
                            handleChange(field.id, field.type, e)
                          }
                          sx={{
                            color: "#0071e3",
                            "&.Mui-checked": {
                              color: "#0071e3",
                            },
                          }}
                        />
                      }
                      label={field.label}
                      sx={{
                        marginBottom: 2,
                        color: "#1d1d1f",
                      }}
                    />
                  )}
                </Box>
              ))}
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal} // Abre o modal ao invés de enviar diretamente
                fullWidth
                sx={{
                  padding: 1.5,
                  fontSize: "16px",
                  textTransform: "none",
                  backgroundColor: "#0071e3",
                  ":hover": {
                    backgroundColor: "#005bb5",
                  },
                }}
              >
                Enviar
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>

      {/* Modal que exibe o formulário de contato, carregamento e depois o resultado */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "80%", sm: "400px" },
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          {showForm ? (
            <>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ fontWeight: "bold", fontSize: "1.8rem" }}
              >
                Dados do Cliente
              </Typography>
              <TextField
                label="E-mail"
                variant="outlined"
                fullWidth
                margin="normal"
                value={emailComprador}
                onChange={(e) => setEmailComprador(e.target.value)}
                error={emailComprador && !isValidEmail(emailComprador)}
                helperText={
                  emailComprador && !isValidEmail(emailComprador)
                    ? "Insira um e-mail válido."
                    : ""
                }
                sx={{
                  ".MuiOutlinedInput-root": {
                    borderRadius: 2,
                    backgroundColor: "#f9f9f9",
                    ":hover": {
                      backgroundColor: "#f0f0f0",
                    },
                  },
                }}
              />
              <TextField
                label="Telefone"
                variant="outlined"
                fullWidth
                margin="normal"
                value={telefoneComprador}
                onChange={handlePhoneChange}
                sx={{
                  ".MuiOutlinedInput-root": {
                    borderRadius: 2,
                    backgroundColor: "#f9f9f9",
                    ":hover": {
                      backgroundColor: "#f0f0f0",
                    },
                  },
                }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                fullWidth
                disabled={
                  !isValidEmail(emailComprador) || telefoneComprador.length < 14
                }
                sx={{
                  marginTop: 2,
                  padding: 1,
                  fontSize: "16px",
                  textTransform: "none",
                  backgroundColor: "#0071e3",
                  ":hover": {
                    backgroundColor: "#005bb5",
                  },
                }}
              >
                OK
              </Button>
            </>
          ) : loading ? (
            <>
              <CircularProgress size={40} sx={{ color: "#0071e3", mb: 2 }} />
              <Typography
                id="modal-title"
                variant="h6"
                component="h2"
                sx={{ marginBottom: 2 }}
              >
                Preparando o seu resultado incrível...
              </Typography>
              <Typography id="modal-description" sx={{ color: "#6e6e73" }}>
                Estamos coletando todas as informações para personalizar a sua
                experiência. Aguarde um momento.
              </Typography>
            </>
          ) : (
            <>
              <Typography
                id="modal-title"
                variant="h6"
                component="h2"
                sx={{ marginBottom: 2 }}
              >
                🎉 Seu resultado está pronto!@
              </Typography>
              <Typography id="modal-description" sx={{ color: "#6e6e73" }}>
                Aproveite sua experiência personalizada com base nas suas
                respostas. Esperamos que você alcance seus objetivos com o
                melhor plano que preparamos para você!
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCloseModal}
                sx={{
                  marginTop: 2,
                  padding: 1,
                  fontSize: "16px",
                  textTransform: "none",
                  backgroundColor: "#0071e3",
                  ":hover": {
                    backgroundColor: "#005bb5",
                  },
                }}
              >
                Fechar
              </Button>
            </>
          )}
        </Box>
      </Modal>
    </Container>
  );
};

export default FluxoProduto;
