import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  TextField,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Button,
} from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { useNavigate } from "react-router-dom"; // Para navegação

const Produtos = () => {
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState([]);
  const navigate = useNavigate(); // Hook para navegação

  useEffect(() => {
    // Simulando o fetch dos dados do arquivo JSON
    fetch("../../products.json")
      .then((response) => response.json())
      .then((data) => setProducts(data))
      .catch((error) => console.error("Erro ao carregar os produtos:", error));
  }, []);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleCardClick = (product) => {
    navigate(`/produto/${product.id}`); // Redireciona para a página de produto
  };

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Container maxWidth="lg" sx={{ padding: { xs: 2, sm: 4 } }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: 100,
          marginBottom: 2,
        }}
      >
        <Button
          startIcon={<ArrowBackRoundedIcon />}
          component="a"
          href="/"
          sx={{
            color: "#0071e3",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          Voltar
        </Button>
      </Box>
      <TextField
        label="Pesquisar produtos"
        variant="outlined"
        fullWidth
        margin="normal"
        value={search}
        onChange={handleSearchChange}
        sx={{
          marginBottom: "20px",
          bgcolor: "#f9f9f9",
          borderRadius: "8px",
          ".MuiOutlinedInput-root": {
            borderRadius: "8px",
            "&:hover": {
              bgcolor: "#f0f0f0",
            },
          },
        }}
      />
      <Grid container spacing={4}>
        {filteredProducts.map((product) => (
          <Grid item xs={12} sm={6} md={4} key={product.id}>
            <Card
              onClick={() => handleCardClick(product)}
              sx={{
                cursor: "pointer",
                backgroundColor: "#ffffff",
                boxShadow: "0 8px 24px rgba(0,0,0,0.1)",
                borderRadius: 4,
                transition: "transform 0.3s ease",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: "0 12px 30px rgba(0,0,0,0.15)",
                },
              }}
            >
              <CardMedia
                component="img"
                height="200"
                image={product.image}
                alt={product.name}
                sx={{ borderRadius: "4px 4px 0 0" }}
              />
              <CardContent
                sx={{
                  padding: 3,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent: "space-between",
                  minHeight: "150px",
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ fontWeight: "bold", fontSize: "1.2rem" }}
                >
                  {product.name}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ marginTop: 1 }}
                >
                  {product.description}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    color: "#0071e3",
                    marginTop: 2,
                    fontSize: "1.1rem",
                  }}
                >
                  Experimente gratis
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Produtos;
