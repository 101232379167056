import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  CardMedia,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Button,
} from "@mui/material";
import { useParams } from "react-router-dom"; // Hook para pegar o ID da URL
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

const Produto = () => {
  const { id } = useParams(); // Pega o ID da URL
  const [product, setProduct] = useState(null);

  useEffect(() => {
    // Simulando o fetch dos dados do arquivo JSON
    fetch("../../products.json")
      .then((response) => response.json())
      .then((data) => {
        const selectedProduct = data.find((p) => p.id.toString() === id);
        setProduct(selectedProduct);
      })
      .catch((error) => console.error("Erro ao carregar os produtos:", error));
  }, [id]);

  const handleFluxoClick = () => {
    if (product) {
      window.location.href = `/fluxo-produto/${product.id}`;
    }
  };

  if (!product) {
    return <Typography>Carregando...</Typography>;
  }

  return (
    <Container
      maxWidth="md"
      sx={{
        padding: { xs: 2, sm: 4 },
        marginBottom: 4,
        backgroundColor: "#f5f5f7",
        borderRadius: 2,
        boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: 100,
          marginBottom: 2,
        }}
      >
        <Button
          startIcon={<ArrowBackRoundedIcon />}
          component="a"
          href="/produtos"
          sx={{
            color: "#0071e3",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          Voltar
        </Button>
      </Box>
      <Typography
        variant="h4"
        component="div"
        sx={{
          fontWeight: "bold",
          marginBottom: 2,
          textAlign: "center",
          fontSize: { xs: "1.5rem", sm: "2rem" },
        }}
      >
        {product.name}
      </Typography>
      <Typography
        variant="body1"
        component="div"
        sx={{
          marginBottom: 3,
          textAlign: "center",
          fontSize: { xs: "1rem", sm: "1.1rem" },
          color: "text.secondary",
        }}
      >
        {product.description}
      </Typography>
      <CardMedia
        component="img"
        height="200"
        image={product.image}
        alt={product.name}
        sx={{
          marginBottom: 3,
          borderRadius: 2,
          width: "100%",
        }}
      />
      <TableContainer>
        <Table>
          <TableBody>
            {product.beneficios.map((beneficio, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Tooltip title={beneficio.tooltip} arrow>
                    <CheckCircleIcon
                      color="primary"
                      sx={{ fontSize: "24px" }}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ fontSize: "1rem", color: "text.primary" }}>
                  {beneficio.descricao}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography
        variant="h6"
        component="div"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          fontSize: "1.3rem",
          color: "#0071e3",
          marginTop: 3,
        }}
      >
        Realize uma demonstração
      </Typography>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleFluxoClick}
        sx={{
          marginTop: 3,
          padding: 1.5,
          fontSize: "16px",
          textTransform: "none",
          backgroundColor: "#0071e3",
          ":hover": {
            backgroundColor: "#005bb5",
          },
        }}
      >
        Iniciar
      </Button>
    </Container>
  );
};

export default Produto;
