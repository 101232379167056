import * as React from "react";
import { alpha } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        backgroundImage:
          theme.palette.mode === "light"
            ? "linear-gradient(180deg, #CEE5FD, #FFF)"
            : "linear-gradient(#02294F, #090E10)",
        backgroundSize: "100% 20%",
        backgroundRepeat: "no-repeat",
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={4} // Mais espaçamento entre os elementos
          useFlexGap
          sx={{
            width: { xs: "100%", sm: "100%" },
            padding: "20px", // Adiciona espaço em torno do conteúdo
            margin: "0 auto", // Centraliza o Stack
            backgroundColor: (theme) => theme.palette.background.paper, // Fundo neutro
            borderRadius: "12px", // Bordas arredondadas
            boxShadow: "0 4px 10px rgba(0,0,0,0.1)", // Sombra suave
          }}
        >
          <Typography
            component="h1"
            variant="h2" // Tamanho de fonte mais amigável e responsivo
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              fontWeight: "bold",
              letterSpacing: "0.05em", // Pequena variação no espaçamento das letras
              transition: "color 0.3s ease", // Transição suave de cores
              "&:hover": {
                color: (theme) =>
                  theme.palette.mode === "light"
                    ? "primary.dark"
                    : "primary.light", // Animação no hover
              },
            }}
          >
            Sua&nbsp;
            <Typography
              component="span"
              variant="h2"
              sx={{
                color: (theme) =>
                  theme.palette.mode === "light"
                    ? "primary.main"
                    : "primary.light",
                fontWeight: "bold",
                textShadow: "1px 1px 3px rgba(0,0,0,0.2)", // Sombra no texto para destaque
              }}
            >
              Saúde
            </Typography>
          </Typography>
          <Typography
            variant="body1"
            textAlign="center"
            color="text.secondary"
            sx={{
              marginTop: "10px",
              lineHeight: "1.6",
              fontSize: "1.1rem",
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            A plataforma que te conecta a profissionais da saúde.
          </Typography>
        </Stack>
        <Box
          id="image"
          sx={(theme) => ({
            mt: { xs: 8, sm: 10 },
            alignSelf: "center",
            height: { xs: 200, sm: 750 },
            width: "100%",
            backgroundImage:
              theme.palette.mode === "light"
                ? 'url("../../headhub.png")'
                : 'url("../../headhub.png")',
            backgroundSize: "cover",
            borderRadius: "10px",
            outline: "1px solid",
            outlineColor:
              theme.palette.mode === "light"
                ? alpha("#BFCCD9", 0.5)
                : alpha("#9CCCFC", 0.1),
            boxShadow:
              theme.palette.mode === "light"
                ? `0 0 12px 8px ${alpha("#9CCCFC", 0.2)}`
                : `0 0 24px 12px ${alpha("#033363", 0.2)}`,
          })}
        />
      </Container>
    </Box>
  );
}
