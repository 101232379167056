import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Button,
  Paper,
} from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Resultado = () => {
  const { state } = useLocation(); // Recebe os dados passados pela navegação
  const [loading, setLoading] = useState(true);
  const [resultado, setResultado] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Simula uma chamada à API ao abrir a tela de resultados
    const payload = {
      produtoId: state.produtoId,
      email: state.email,
      telefone: state.telefone,
      formValues: state.formValues,
    };

    setLoading(false);
    setResultado(state); // Aqui pegamos os dados do state enviados pelo FluxoProduto

    console.log(payload);

    // axios
    //   .post("https://api.exemplo.com/enviar-dados", payload)
    //   .then((response) => {
    //     setLoading(false);
    //     setResultado(state); // Aqui pegamos os dados do state enviados pelo FluxoProduto
    //   })
    //   .catch((error) => {
    //     console.error("Erro na chamada da API:", error);
    //     setLoading(false);
    //   });
  }, [state]);

  if (loading) {
    return (
      <Container
        maxWidth="md"
        sx={{
          padding: { xs: 2, sm: 4 },
          marginBottom: 4,
          backgroundColor: "#f5f5f7",
          borderRadius: 2,
          boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
        }}
      >
        <CircularProgress size={40} sx={{ color: "#0071e3", mb: 2 }} />
        <Typography variant="h6" sx={{ textAlign: "center", marginTop: 2 }}>
          Estamos preparando o seu resultado incrível...
        </Typography>
      </Container>
    );
  }

  if (!resultado) {
    return (
      <Typography variant="h6" sx={{ textAlign: "center", marginTop: 2 }}>
        Ocorreu um erro ao carregar os dados.
      </Typography>
    );
  }

  return (
    <Container
      maxWidth="md"
      sx={{
        padding: { xs: 2, sm: 4 },
        marginBottom: 4,
        backgroundColor: "#f5f5f7",
        borderRadius: 2,
        boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
      }}
    >
      {/* Cabeçalho da página */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: 100,
          marginBottom: 2,
        }}
      >
        <Button
          startIcon={<ArrowBackRoundedIcon />}
          onClick={() => navigate("/produtos")}
          sx={{
            color: "#0071e3",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          Voltar
        </Button>
      </Box>

      {/* Título e introdução */}
      <Typography
        variant="h4"
        sx={{ textAlign: "center", fontWeight: "bold", marginBottom: 3 }}
      >
        🎉 Seu resultado está pronto!
      </Typography>
      <Typography variant="h6" sx={{ textAlign: "center", marginBottom: 3 }}>
        Aproveite sua experiência personalizada com base nas suas respostas.
      </Typography>

      {/* Informações do produto */}
      <Box sx={{ textAlign: "center", marginBottom: 3 }}>
        <Typography
          variant="body1"
          sx={{ marginBottom: 2, fontWeight: "bold", color: "#0071e3" }}
        >
          Produto: {resultado.produtoId}
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          E-mail: {resultado.email}
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          Telefone: {resultado.telefone}
        </Typography>
      </Box>

      {/* Respostas do formulário */}
      {/* <Typography variant="h6" sx={{ textAlign: "center", marginBottom: 3 }}>
        Respostas fornecidas:
      </Typography>
      <ul>
        {resultado.formValues.map((item, index) => (
          <li key={index} style={{ marginBottom: 8 }}>
            <Typography variant="body1">
              Pergunta: {item.id}, Resposta: {item.resposta}
            </Typography>
          </li>
        ))}
      </ul> */}

      {/* Box de oferta */}
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          borderRadius: 4,
          backgroundColor: "#fff7e6",
          marginTop: 4,
          textAlign: "center",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", color: "#f57c00", marginBottom: 2 }}
        >
          🚀 Aproveite nossa oferta exclusiva!
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 3, color: "#6e6e73" }}>
          Faça o upgrade para a versão paga e tenha acesso total diretamente no
          seu zap ainda mais resultados personalizados.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          sx={{
            textTransform: "none",
            fontSize: "18px",
            fontWeight: "bold",
            padding: "12px 24px",
            backgroundColor: "#f57c00",
            ":hover": {
              backgroundColor: "#e65100",
            },
          }}
          onClick={() => navigate("/upgrade")}
        >
          Quero fazer o upgrade agora!
        </Button>
        <Box sx={{ marginTop: 2 }}>
          <CheckCircleIcon sx={{ color: "#4caf50", fontSize: 32 }} />
          <Typography variant="body2" sx={{ color: "#4caf50", marginTop: 1 }}>
            Aproveite a experiência completa e atinja seus objetivos com mais
            rapidez!
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default Resultado;
