import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import AutoFixHighRoundedIcon from "@mui/icons-material/AutoFixHighRounded";
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import SettingsSuggestRoundedIcon from "@mui/icons-material/SettingsSuggestRounded";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";

const items = [
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: "Avaliações de Profissionais e Atendimento",
    description:
      "Escolha com confiança. Nossa plataforma permite que você veja avaliações detalhadas dos profissionais antes de agendar uma consulta, garantindo que você receba o melhor atendimento possível baseado em experiências reais de outros pacientes.",
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: "Integração com Lojas Parceiras",
    description:
      "Beneficie-se de uma integração única com lojas de produtos de saúde e bem-estar. Após sua consulta, adquira suplementos, equipamentos ou medicamentos recomendados diretamente através de nossas lojas parceiras, com condições exclusivas.",
  },
  {
    icon: <AddToQueueIcon />,
    title: "Atendimento Online",
    description:
      "Converse com especialistas de qualquer lugar! Nosso sistema de atendimento online permite consultas via videoconferência, oferecendo conforto e conveniência sem você precisar sair de casa.",
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: "Monitoramento Contínuo de Saúde",
    description:
      "Mantenha seu histórico de saúde seguro e acessível. Com nosso serviço de monitoramento contínuo, tanto pacientes quanto profissionais podem acompanhar progressos e ajustar tratamentos com base em dados reais e atualizados.",
  },
  {
    icon: <AppRegistrationIcon />,
    title: "Programas de Bem-estar Personalizados",
    description:
      "Aproveite programas de bem-estar personalizados, criados por especialistas para atender às suas necessidades específicas. Seja uma rotina de exercícios, dieta especial ou técnicas de relaxamento, personalize sua jornada para saúde e bem-estar.",
  },
  {
    icon: <SupportAgentRoundedIcon />,
    title: "Suporte Multidisciplinar",
    description:
      "Nossa rede inclui uma ampla gama de especialistas em saúde, de nutricionistas a psicólogos, oferecendo um suporte holístico. Isso significa que você pode acessar diversos tipos de tratamento e conselhos, tudo em um só lugar.",
  },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: "white",
        bgcolor: "#06090a",
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography component="h2" variant="h4">
            Destaques
          </Typography>
          <Typography variant="body1" sx={{ color: "grey.400" }}>
            Descubra um novo jeito de cuidar da sua saúde com nossa plataforma,
            que conecta pacientes a profissionais de saúde de forma inovadora e
            eficaz. Explore os benefícios que transformam o acesso à saúde:
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: "100%",
                  border: "1px solid",
                  borderColor: "grey.800",
                  background: "transparent",
                  backgroundColor: "grey.900",
                }}
              >
                <Box sx={{ opacity: "50%" }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "grey.400" }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
